<template>
  <router-view />
</template>

<script>
export default {
  name: 'LayoutEmpty',
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>
